import React from "react"
import Header from "../components/header.js"
import Seo from "../components/seo.js"

const ThankYouPage = () => (
  <>
    <Seo
      title="Marc Kreidler Portfolio"
      description="NYC-based WordPress Theme Developer &amp; Gatsby Headless WordPress Developer"
      ogTitle="Marc Kreidler Portfolio"
      ogDescription="NYC-based WordPress Theme Developer &amp; Gatsby Headless WordPress Developer"
      twTitle="Marc Kreidler Portfolio"
      twDescription="NYC-based WordPress Theme Developer &amp; Gatsby Headless WordPress Developer"
      featuredImage="https://mkreidler.com/static/46755536fd4798f805d2311de6629fdd/078c3/marc.webp"
      ogImage="https://mkreidler.com/static/46755536fd4798f805d2311de6629fdd/078c3/marc.webp"
      twImage="https://mkreidler.com/static/46755536fd4798f805d2311de6629fdd/078c3/marc.webp"
    />
    <div className="home bg-offWhite">
      <div className="lg:flex">
        <Header />
        <div className="entry-content w-full pt-6 pb-16 lg:py-12">
          <div className="lg:my-12 px-6 lg:px-12 max-w-6xl mx-auto">
            <p
              style={{
                textAlign: "center",
                fontSize: "8em",
                lineHeight: 1,
              }}
            >
              📬
            </p>
            <h1 style={{ textAlign: "center" }}>Thank You</h1>
            <p style={{ textAlign: "center" }}>
              Thank you for reaching out. I'll be in touch soon!
            </p>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default ThankYouPage
